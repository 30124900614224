import React from "react";
import { dateToDay, dayToDate } from "../util/dates";
import DatePicker from "react-datepicker";

export interface CompactDatePickerProps {
  selected?: string;
  onChange: (date: string) => void;
}

export function CompactDatePicker(props: CompactDatePickerProps): JSX.Element | null {
  const { selected, onChange } = props;
  return (
    <DatePicker
      selected={selected !== undefined ? dayToDate(selected) : undefined}
      onChange={(date) => {
        if (date instanceof Date) {
          onChange(dateToDay(date));
        }
      }}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="time"
      dateFormat="Pp"
      locale="es"
    />
  );
}
