import React from "react";

import { Line } from "react-chartjs-2";
import { LineChartData } from "../routes/home/context";

interface MultiLineChartProps {
  lineChart: LineChartData;
}

export default function MultiLineChart({ lineChart }: MultiLineChartProps) {
  const data = lineChart.data.map(({ color, name, valuesArray }) => ({
    label: name,
    data: valuesArray,
    fill: false,
    borderColor: color,
    tension: 0.4,
  }));
  return (
    <Line
      data={{ labels: lineChart.labels, datasets: data }}
      options={{
        scales: {
          y: {
            ticks: { color: "#333333", font: { weight: "400", size: 14 } },
          },
          x: {
            ticks: { color: "#333333", font: { weight: "400", size: 12 } },
          },
        },
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              color: "#333333",
              font: { weight: "600", size: 14 },
              padding: 16,
              boxWidth: 14,
            },
          },
        },
      }}
    />
  );
}
