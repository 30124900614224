import React, { useState } from "react";
import { useCategory } from "../contexts/category-context";
import {
  NewPeriod,
  Period,
  PeriodChangeset,
  applyPeriodChangeset,
} from "../routes/home/context";
import { Modal } from "./modal";
import { PeriodForm } from "./period-form";

export interface UpdatePeriodProps {
  onClose: () => void;
  onUpdate: (newPeriod: PeriodChangeset) => void;
  period: Period;
}

const hasChanged = (data: Partial<NewPeriod>) => {
  return (
    data.description !== undefined ||
    data.categoryID !== undefined ||
    data.start !== undefined ||
    data.end !== undefined
  );
};

function getUpdateValue<T>(previous: T, next: T) {
  return previous !== next ? next : undefined;
}

export function UpdatePeriod({
  onClose,
  onUpdate,
  period,
}: UpdatePeriodProps): JSX.Element | null {
  const { categories } = useCategory();
  const [changeset, setChangeset] = useState<PeriodChangeset>({});
  const updatedPeriod = applyPeriodChangeset(changeset, period, categories);
  const handleSubmit = () => {
    if (hasChanged(changeset)) {
      onUpdate(changeset);
    }
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <PeriodForm
        data={{ ...updatedPeriod, categoryID: updatedPeriod.category.id }}
        onChange={(data) =>
          setChangeset({
            description: getUpdateValue(period.description, data.description),
            start: getUpdateValue(period.start, data.start),
            end: getUpdateValue(period.end, data.end),
            categoryID: getUpdateValue(period.category.id, data.categoryID),
          })
        }
        onSubmit={handleSubmit}
        variant="update"
      />
    </Modal>
  );
}
