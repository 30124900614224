import React, { createContext, useContext, useEffect, useState } from "react";
import { client } from "./client";
import { useUser } from "./user-context";

const defaultColors = [
  "#227C9D",
  "#17C3B2",
  "#FFCB77",
  "#FE6D73",
  "#694873",
  "#363537",
  "#EF2D56",
  "#D4AFB9",
  "#D95D39",
  "#214E34",
];

interface Category {
  id: string;
  name: string;
  color: string;
}

interface NewCategory {
  name: string;
}

interface UseCategory {
  categories: Map<string, Category>;
  canAddCategories: boolean;
  createCategory: (newCategory: NewCategory) => Promise<Category>;
  deleteCategory: (id: string) => Promise<void>;
}

const context = createContext<UseCategory>(null as never);

export function CategoriesContext(props: {
  children: React.ReactNode;
}): JSX.Element | null {
  const [categories, setCategories] = useState<Map<string, Category>>(new Map());
  const { user } = useUser();

  async function loadCategories() {
    const { categories } = await client.$("categories").get<{ categories: Category[] }>();
    setCategories(new Map(categories.map((category) => [category.id, category])));
  }

  useEffect(() => {
    if (user !== undefined) {
      loadCategories();
    }
  }, [user]);

  return (
    <context.Provider
      value={{
        categories,
        canAddCategories: defaultColors.length > [...categories.values()].length,
        createCategory: async ({ name }) => {
          const availableColors = defaultColors.filter(
            (defaultColors) =>
              ![...categories.values()].some(({ color }) => color === defaultColors)
          );
          const random = Math.random();
          const index = Number(Number(random * 100).toFixed(0)) % availableColors.length;
          const newCategory = {
            name,
            color: availableColors[index],
          };
          const { id } = await client
            .$("categories")
            .post<{ category: { name: string; color: string } }, { id: string }>({
              body: { category: newCategory },
            });
          const createdCategory = { ...newCategory, id };
          const newCategories = new Map(categories);
          newCategories.set(id, createdCategory);
          setCategories(newCategories);
          return createdCategory;
        },
        deleteCategory: async (id: string) => {
          await client.$("categories").$(id).delete();
        },
      }}
    >
      {props.children}
    </context.Provider>
  );
}

export function useCategory(): UseCategory {
  return useContext<UseCategory>(context);
}
