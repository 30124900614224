import { AnimateSharedLayout, motion } from "framer-motion";
import React, { useMemo } from "react";
import styled from "styled-components";
import { useHome } from "../context";

const MenuRoot = styled.div`
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 8px;
  overflow: hidden;
  height: 32px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
  background: #e7ecef;
`;

const MenuOption = styled.div`
  position: relative;
  height: 100%;
  width: 120px;
  > * {
    width: 100%;
    height: 100%;
  }
  > div {
    box-shadow: inset 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
  }
  > button {
    color: var(--primary-color);
    background: transparent;
    :hover {
      box-shadow: none;
    }
  }
`;

interface MenuSelectorProps {
  options: Array<{ selected: boolean; label: string; onClick: () => void }>;
}
function MenuSelector(props: MenuSelectorProps) {
  return (
    <MenuRoot>
      <AnimateSharedLayout>
        {props.options.map((option) => (
          <MenuOption>
            <button onClick={option.onClick}>{option.label}</button>
            {option.selected && <motion.div layoutId="menu" />}
          </MenuOption>
        ))}
      </AnimateSharedLayout>
    </MenuRoot>
  );
}

const Root = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Chart = "pie" | "line";

interface HomeFiltersProps {
  chart: Chart;
  onChangeChart: (chart: Chart) => void;
}

export function HomeFilters(props: HomeFiltersProps): JSX.Element | null {
  const homeValue = useHome();
  if (!homeValue.loaded) {
    return null;
  }
  const { changeChartDisplayPeriod, displayPeriod } = homeValue;

  const periodOptions: MenuSelectorProps["options"] = useMemo(
    () => [
      {
        label: "Todo",
        onClick: () => changeChartDisplayPeriod("all"),
        selected: displayPeriod === "all",
      },
      {
        label: "Último mes",
        onClick: () => changeChartDisplayPeriod("last-month"),
        selected: displayPeriod === "last-month",
      },
    ],
    [displayPeriod]
  );
  const chartOptions: MenuSelectorProps["options"] = useMemo(
    () => [
      {
        label: "Circular",
        onClick: () => props.onChangeChart("pie"),
        selected: props.chart === "pie",
      },
      {
        label: "Líneas",
        onClick: () => props.onChangeChart("line"),
        selected: props.chart === "line",
      },
    ],
    [props.chart]
  );

  return (
    <Root>
      <MenuSelector options={periodOptions} />
      <MenuSelector options={chartOptions} />
    </Root>
  );
}
