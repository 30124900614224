import React, { useState } from "react";
import { NewPeriod } from "../routes/home/context";
import { dateToDay, dayToDate } from "../util/dates";
import { Modal } from "./modal";
import { PeriodForm } from "./period-form";

export interface CreatePeriodProps {
  prevDay?: string;
  onClose: () => void;
  onCreate: (newPeriod: NewPeriod) => Promise<void>;
}

const getDefaultEndDate = (prevStart: string) => {
  const previousDate = dayToDate(prevStart);
  previousDate.setHours(previousDate.getHours() + 2);
  return dateToDay(previousDate);
};

export function CreatePeriod(props: CreatePeriodProps): JSX.Element | null {
  const start = props.prevDay ?? dateToDay(new Date());
  const [data, setData] = useState<Partial<NewPeriod>>({
    description: "",
    start,
    end: getDefaultEndDate(start),
    categoryID: "",
  });

  const dataIsPeriod = (data: Partial<NewPeriod>): data is NewPeriod => {
    return data.categoryID !== "";
  };

  const handleSubmit = () => {
    if (dataIsPeriod(data)) {
      props.onCreate(data);
      props.onClose();
    }
  };

  return (
    <Modal onClose={props.onClose}>
      <PeriodForm data={data} onChange={setData} onSubmit={handleSubmit} />
    </Modal>
  );
}
