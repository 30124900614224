import React, { useState } from "react";
import styled from "styled-components";
import { TextInput } from "../../../components/text-input";
import { NewUser, useUser } from "../../../contexts/user-context";
import { CtaText } from "./cta-text";

const Root = styled.form`
  width: 400px;
  height: 380px;
  position: relative;
  border-radius: 4px;
  h2 {
    text-align: center;
    color: var(--primary-color);
  }
  h2,
  input {
    margin-bottom: 16px;
  }
  button {
    margin: auto auto 0;
  }
`;

const initialCredentials: NewUser = { email: "", password: "", name: "" };

export interface SignupCardProps {
  onChangeView: () => void;
}

export function SignupCard({ onChangeView }: SignupCardProps): JSX.Element | null {
  const [newUser, setNewUser] = useState<NewUser>(initialCredentials);
  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const { signup } = useUser();

  return (
    <Root
      className="card"
      onSubmit={async (e) => {
        e.preventDefault();
        const { email, password, name } = newUser;
        if (email && password && name) {
          await signup(newUser);
          onChangeView();
        } else {
          setTriedToSubmit(true);
        }
      }}
    >
      <h2>Sign up</h2>
      <TextInput
        label="Correo electrónico"
        value={newUser.email}
        onChange={(email) => setNewUser({ ...newUser, email })}
        placeholder="time@tracker.com"
        type="email"
      />
      <TextInput
        label="Nombre"
        value={newUser.name}
        onChange={(name) => setNewUser({ ...newUser, name })}
        placeholder="Time tracker"
      />
      <TextInput
        label="Contraseña"
        value={newUser.password}
        onChange={(password) => setNewUser({ ...newUser, password })}
        placeholder="********"
        type="password"
      />
      <button type="submit">Sign up</button>
      <CtaText screen="signup" onClick={onChangeView}></CtaText>
    </Root>
  );
}
