import React from "react";
import { CategoriesContext } from "./category-context";
import { UserContext } from "./user-context";

export interface DataContextsProps {
  children: React.ReactNode;
}

export function DataContexts({ children }: DataContextsProps): JSX.Element | null {
  return (
    <UserContext>
      <CategoriesContext>{children}</CategoriesContext>
    </UserContext>
  );
}
