/* eslint-disable @typescript-eslint/ban-types */
export type Files = Record<string, Blob | Blob[]>;

export type Serialized<T> = T extends Set<infer K>
  ? K[]
  : T extends Map<unknown, infer V>
  ? V[]
  : T extends Date
  ? string
  : T extends Array<infer E>
  ? Array<Serialized<E>>
  : T extends { [P in keyof T]: T[P] }
  ? { [P in keyof T as T[P] extends Function ? never : P]: Serialized<T[P]> }
  : T;

async function getBody<O>(response: Response): Promise<O> {
  const body = await response.text();
  try {
    return JSON.parse(body);
  } catch {
    return undefined as any;
  }
}

class Client {
  private url: string;
  constructor(options: { url: string }) {
    this.url = options.url;
  }

  async get<O>(data: { params?: URLSearchParams } = {}): Promise<Serialized<O>> {
    const request = this.buildRequest({ method: "GET", ...data });
    const response = await fetch(request);
    if (!response.ok) {
      throw new ErrorResponseNotOK(response);
    }
    return await getBody(response);
  }

  async post<I, O = void>(data: {
    params?: URLSearchParams;
    body?: I;
    bodyNameInForm?: string;
    files?: Files;
  }): Promise<Serialized<O>> {
    const request = this.buildRequest({
      method: "POST",
      body: data.body as any,
      ...data,
    });
    const response = await fetch(request);
    if (!response.ok) {
      throw new ErrorResponseNotOK(response);
    }
    return await getBody(response);
  }

  async put<I, O = void>(data: {
    params?: URLSearchParams;
    body?: I;
    bodyNameInForm?: string;
    files?: Files;
  }): Promise<Serialized<O>> {
    const request = this.buildRequest({
      method: "PUT",
      body: data.body as any,
      ...data,
    });
    const response = await fetch(request);
    if (!response.ok) {
      throw new ErrorResponseNotOK(response);
    }
    return await getBody(response);
  }

  async delete<O = void>(data?: { params?: URLSearchParams }): Promise<Serialized<O>> {
    const request = this.buildRequest({ method: "DELETE", ...data });
    const response = await fetch(request);
    if (!response.ok) {
      throw new ErrorResponseNotOK(response);
    }
    return await getBody(response);
  }

  $(path: string): Client {
    return new Client({ url: this.url + "/" + path });
  }

  private buildRequest(request: {
    method: "GET" | "POST" | "PUT" | "DELETE";
    params?: URLSearchParams;
    body?: object;
    bodyNameInForm?: string;
    files?: Files;
  }): Request {
    const parameterString =
      request.params !== undefined ? "?" + request.params.toString() : "";
    const urlString = this.url + parameterString;
    let body: FormData | string | undefined;
    const headers = new Headers();
    if (request.body !== undefined) {
      body = JSON.stringify(request.body);
      headers.set("content-type", "application/json");
    }
    return new Request(urlString, {
      method: request.method,
      body,
      headers,
      credentials: "include",
    });
  }
}

class ErrorResponseNotOK extends Error {
  response: Response;
  constructor(response: Response) {
    if (response.ok || response.bodyUsed) {
      throw new Error(
        "ErrorResponseNotOK is meant to be used with an error response with unread body"
      );
    }
    super(`got http response with error code: ${response.status}`);
    this.response = response;
  }
}

export const client = new Client({
  url:
    import.meta.env.MODE === "production"
      ? "https://timetracker-api.sagaprojects.app/"
      : "http://localhost:3000",
});
