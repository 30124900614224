import React from "react";
import { Switch, BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { LoadingScreen } from "../components/loading-screen";
import { useUser } from "../contexts/user-context";
import { Authentication } from "./authentication";
import { Home } from "./home";
import { HomeProvider } from "./home/context";

export function Routes(): JSX.Element | null {
  const { user, loaded } = useUser();
  const loggedIn = user !== undefined;
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          {loggedIn && <Redirect to="/home" />}
          {!loaded ? <LoadingScreen /> : <Authentication />}
        </Route>
        <Route path="/home">
          {!loggedIn && <Redirect to="/" />}
          <HomeProvider>
            <Home />
          </HomeProvider>
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}
