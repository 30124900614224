import React, { useRef } from "react";
import styled from "styled-components";
import { useClickOutside } from "../util/hooks";

const CenteredFlexbox = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Root = styled(CenteredFlexbox)`
  z-index: 1;
  opacity: 0.5;
  background: #333333;
`;

const Wrapper = styled(CenteredFlexbox)`
  z-index: 2;
  box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.15);
`;

export interface ModalProps {
  children: React.ReactNode;
  onClose: () => void;
}

export function Modal({ children, onClose }: ModalProps): JSX.Element | null {
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, onClose);
  return (
    <>
      <Root />
      <Wrapper>
        <div ref={ref}>{children}</div>
      </Wrapper>
    </>
  );
}
