import { capitalizeString } from "./functions";

const locale = "es-ES";

const datetoDayFormatter = new Intl.DateTimeFormat(locale, {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
});

export function dateToDay(date: Date): string {
  const arrayDay = datetoDayFormatter
    .format(date)
    .replaceAll("/", "-")
    .replaceAll(" ", "-")
    .replaceAll(":", "-")
    .replaceAll(",", "")
    .split("-");
  return `${arrayDay[2]}-${arrayDay[1]}-${arrayDay[0]}-${arrayDay[3]}-${arrayDay[4]}`;
}

export function dayToDate(date: string): Date {
  const stringDates = date.split("-");
  return new Date(
    Number(stringDates[0]),
    Number(stringDates[1]) - 1,
    Number(stringDates[2]),
    Number(stringDates[3] ?? "0"),
    Number(stringDates[4] ?? "0")
  );
}

const dateFormatter = new Intl.DateTimeFormat(locale, {
  day: "2-digit",
  month: "2-digit",
  year: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
});

export function dayFormatter(stringDate: string): string {
  const date = dayToDate(stringDate);
  return dateFormatter.format(date);
}

const hourFormatter = new Intl.DateTimeFormat(locale, {
  hour: "2-digit",
  minute: "2-digit",
});

export function formatHour(stringDate: string): string {
  const date = dayToDate(stringDate);
  return hourFormatter.format(date);
}

const weekDayFormatter = new Intl.DateTimeFormat(locale, {
  day: "numeric",
  weekday: "long",
  month: "long",
});

export function formatWeekday(stringDate: string): string {
  const date = dayToDate(stringDate);
  return capitalizeString(weekDayFormatter.format(date));
}

export function sameDay(start: string, end: string): boolean {
  const startDate = dayToDate(start);
  const endDate = dayToDate(end);
  return (
    startDate.getDate() === endDate.getDate() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getFullYear() === endDate.getFullYear()
  );
}
