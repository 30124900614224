import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  createContext,
  useContext,
} from "react";
import { useCategory } from "../../contexts/category-context";
import { client } from "../../contexts/client";
import { useUser } from "../../contexts/user-context";
import { dateToDay, dayToDate } from "../../util/dates";

export interface Period {
  id: string;
  description: string;
  start: string;
  end: string;
  created: string;
  updated: string;
  category: {
    id: string;
    name: string;
    color: string;
  };
}

export interface NewPeriod {
  description: string;
  start: string;
  end: string;
  categoryID: string;
}

export interface PeriodChangeset {
  description?: string;
  start?: string;
  end?: string;
  categoryID?: string;
}

export interface Category {
  id: string;
  name: string;
  color: string;
}

interface CategoryFrequency {
  id: string;
  color: string;
  name: string;
  frequency: number;
  hours: number;
}

export type LineChartData = {
  labels: string[];
  data: Array<{
    valuesArray: number[];
    color: string;
    name: string;
    id: string;
  }>;
};

export interface HomeData {
  charts: {
    categoryFrequencies: Array<CategoryFrequency>;
    totalHours: number;
    lineChart: LineChartData;
  };
  periods: Array<Period>;
}

export const applyPeriodChangeset = (
  changeset: PeriodChangeset,
  period: Period,
  categories: Map<string, Category>
): Period => {
  const category = categories.get(changeset.categoryID ?? period.category.id)!;
  return {
    ...period,
    description: changeset.description ?? period.description,
    start: changeset.start ?? period.start,
    end: changeset.end ?? period.end,
    category,
  };
};

const sortPeriods = (a: Period, b: Period) =>
  dayToDate(b.start).getTime() > dayToDate(a.start).getTime() ? 1 : -1;

export type ChartDisplayPeriod = "all" | "last-month";
export type UseHome =
  | { loaded: false }
  | {
      loaded: true;
      data: HomeData;
      displayPeriod: ChartDisplayPeriod;
      changeChartDisplayPeriod: (period: ChartDisplayPeriod) => void;
      createPeriod: (newPeriod: NewPeriod) => Promise<void>;
      updatePeriod: (id: string, changeset: PeriodChangeset) => Promise<void>;
      deletePeriod: (periodID: string) => Promise<void>;
    };

const context = createContext<UseHome>({ loaded: false });
export function HomeProvider(props: { children: React.ReactNode }): JSX.Element {
  const [loaded, setLoaded] = useState(false);
  const [chartDisplayPeriod, setChartDisplayPeriod] = useState<ChartDisplayPeriod>("all");
  const newID = useRef(0);
  const [data, setData] = useState<HomeData>();
  const { categories } = useCategory();
  const { user } = useUser();

  async function loadData() {
    const params = new URLSearchParams([["chartPeriod", chartDisplayPeriod]]);
    const response = await client.$("home").get<HomeData>({ params });
    setData(response);
    setLoaded(true);
  }

  useEffect(() => {
    if (user !== undefined) {
      loadData();
    }
  }, [user?.id, chartDisplayPeriod]);

  const value: UseHome = useMemo(() => {
    if (!loaded) return { loaded };
    return {
      loaded,
      data: data!,
      displayPeriod: chartDisplayPeriod,
      changeChartDisplayPeriod: setChartDisplayPeriod,
      createPeriod: async (newPeriod) => {
        try {
          const now = new Date();
          newID.current++;
          const category = categories.get(newPeriod.categoryID)!;
          const provisionalPeriodID = `new-period-${newID.current}`;
          const period: Period = {
            id: provisionalPeriodID,
            description: newPeriod.description,
            start: newPeriod.start,
            end: newPeriod.end,
            category,
            created: dateToDay(now),
            updated: dateToDay(now),
          };
          const updatedPeriods: Period[] = [...data!.periods, period].sort(sortPeriods);
          setData({ ...data!, periods: updatedPeriods });
          await client.$("periods").post({ body: { period: newPeriod } });
          await loadData();
        } catch (error) {
          console.log(error);
          setData(data);
        }
      },
      updatePeriod: async (updatedID, changeset) => {
        const periodIndex = data!.periods.findIndex(({ id }) => id === updatedID);
        const updated = applyPeriodChangeset(
          changeset,
          data!.periods[periodIndex],
          categories
        );
        setData({
          ...data!,
          periods: [
            ...data!.periods.slice(0, periodIndex),
            updated,
            ...data!.periods.slice(periodIndex + 1),
          ].sort(sortPeriods),
        });
        try {
          await client
            .$("periods")
            .$(updatedID)
            .put<{ changeset: PeriodChangeset }>({ body: { changeset } });
          await loadData();
        } catch (error) {
          console.log(error);
          setData(data);
        }
      },
      deletePeriod: async (deletedID) => {
        const periodIndex = data!.periods.findIndex(({ id }) => id === deletedID);
        setData({
          ...data!,
          periods: [
            ...data!.periods.slice(0, periodIndex),
            ...data!.periods.slice(periodIndex + 1),
          ],
        });
        try {
          await client.$("periods").$(deletedID).delete();
          await loadData();
        } catch (error) {
          console.log(error);
          setData(data);
        }
      },
    };
  }, [data, loaded, chartDisplayPeriod]);

  return <context.Provider value={value}>{props.children}</context.Provider>;
}

export function useHome(): UseHome {
  return useContext(context);
}
