import React, { useState, useEffect } from "react";
import { ParentSize } from "@visx/responsive";
import styled, { css } from "styled-components";

import { CreatePeriod } from "../../components/create-period";
import { useHome } from "./context";
import { useUser } from "../../contexts/user-context";
import { PeriodsList } from "./components/periods-list";
import { HomeCharts } from "./components/home-charts";
import { LoadingScreen } from "../../components/loading-screen";
import { AnimatePresence, motion } from "framer-motion";
import { HourGlass } from "../../components/hourglass";

const UltimateRoot = styled.div`
  height: 100%;
  width: 100%;
  background: #fafafa;
`;

const Root = styled(motion.div)<{ loaded: boolean }>`
  height: 100%;
  width: 100%;
  ${({ loaded }) =>
    !loaded &&
    css`
      > * {
        visibility: hidden;
      }
    `}
`;

const LoadingRoot = styled(motion.div)`
  position: fixed;
  display: grid;
  place-content: center;
  z-index: 1;
  height: 100%;
  width: 100%;
`;

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  padding: 24px 32px;
  position: relative;
  .total-amount {
    position: absolute;
    right: 40px;
    bottom: 40px;
  }
`;

const RightContainer = styled(Container)`
  overflow: hidden;
  display: grid;
  grid: "title new" auto "content content" 1fr / auto auto;
  grid-row-gap: 16px;
  padding: 0;
  > button {
    grid-area: new;
    place-self: end end;
    background-color: var(--primary-color);
    margin: 24px 24px 4px 0;
  }
  h1 {
    margin: 24px 0 0 24px;
    grid-area: title;
    color: var(--primary-color);
  }
  > *:last-child {
    grid-area: content;
  }
  background-color: var(--light-color);
`;

const Logout = styled.p`
  position: absolute;
  top: 28px;
  right: 40px;
  color: var(--red-color);
  cursor: pointer;
  font-weight: 700;
  :hover {
    text-decoration: underline;
  }
`;

export function Home(): JSX.Element | null {
  const homeValue = useHome();
  const [creating, setCreating] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { logout } = useUser();

  useEffect(() => {
    const timeout = setTimeout(() => setLoaded(true), 1_000);
    return () => clearTimeout(timeout);
  }, []);

  if (!homeValue.loaded) {
    return <LoadingScreen />;
  }
  const { data, createPeriod, updatePeriod, deletePeriod } = homeValue;
  const { periods, charts } = data;
  const { totalHours } = charts;

  return (
    <UltimateRoot>
      <AnimatePresence initial={false}>
        {!loaded && (
          <LoadingRoot initial={false} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <HourGlass />
          </LoadingRoot>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {loaded && (
          <Root loaded={loaded} animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
            <Layout>
              <RightContainer>
                <h1>Timetracker</h1>
                <button onClick={() => setCreating(true)}>Nuevo</button>
                <PeriodsList
                  periods={periods}
                  deletePeriod={deletePeriod}
                  updatePeriod={updatePeriod}
                />
              </RightContainer>
              <Container>
                <Logout onClick={logout}>Desconectarse</Logout>
                <div style={{ height: "100%" }}>
                  <ParentSize>
                    {({ width, height }) => (
                      <HomeCharts size={{ height: height, width }} chartData={charts} />
                    )}
                  </ParentSize>
                </div>
                <h3 className="total-amount">{`${totalHours.toFixed(1)}h / ${(
                  totalHours / 24
                ).toFixed(1)} días`}</h3>
              </Container>
            </Layout>
            <AnimatePresence>
              {creating && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <CreatePeriod
                    onCreate={createPeriod}
                    onClose={() => setCreating(false)}
                    prevDay={periods[0]?.end ?? undefined}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </Root>
        )}
      </AnimatePresence>
    </UltimateRoot>
  );
}
