import React from "react";
import ReactDOM from "react-dom";
import { DataContexts } from "./contexts";
import { Routes } from "./routes";

const root = document.querySelector("#root");

ReactDOM.render(
  <React.StrictMode>
    <DataContexts>
      <Routes />
    </DataContexts>
  </React.StrictMode>,
  root
);
