import React, { useState } from "react";
import styled from "styled-components";
import { TextInput } from "../../../components/text-input";
import { UserCredentials, useUser } from "../../../contexts/user-context";
import { CtaText } from "./cta-text";

const Root = styled.form`
  width: 400px;
  height: 300px;
  position: relative;
  border-radius: 4px;
  h2 {
    text-align: center;
    color: var(--primary-color);
  }
  h2,
  input {
    margin-bottom: 16px;
  }
  button {
    margin: auto auto 0;
  }
  .fetch-error-message {
    color: var(--red-color);
    font-size: 12px;
  }
`;

const initialCredentials: UserCredentials = { email: "", password: "" };

export interface LoginCardProps {
  onChangeView: () => void;
}

export function LoginCard({ onChangeView }: LoginCardProps): JSX.Element | null {
  const [credentials, setCredentials] = useState<UserCredentials>(initialCredentials);
  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const { login } = useUser();

  return (
    <Root
      className="card"
      onSubmit={async (e) => {
        e.preventDefault();
        if (credentials.email && credentials.password) {
          try {
            await login(credentials);
          } catch {
            setFetchError(true);
          }
        } else {
          setTriedToSubmit(true);
        }
      }}
    >
      <h2>Login</h2>
      <TextInput
        label="Correo electrónico"
        value={credentials.email}
        onChange={(email) => setCredentials({ ...credentials, email })}
        placeholder="time@tracker.com"
        type="email"
      />
      <TextInput
        label="Contraseña"
        value={credentials.password}
        onChange={(password) => setCredentials({ ...credentials, password })}
        placeholder="********"
        type="password"
      />
      {fetchError && (
        <p className="fetch-error-message">Correo o contranseña incorrectas</p>
      )}
      <button type="submit">Login</button>
      <CtaText screen="login" onClick={onChangeView}></CtaText>
    </Root>
  );
}
