import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Category } from "../routes/home/context";
import { dayFormatter, sameDay, formatHour } from "../util/dates";
import { GradientShell } from "./gradient-shell";

const Root = styled.div`
  height: 32px;
  box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  > *:first-child {
    margin-left: 16px;
  }
  > *:nth-child(3) {
    padding-right: 16px;
  }
`;

const Period = styled(Root)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  place-content: center;
  background: var(--white-color);
  position: relative;
  > *:nth-child(2) {
    margin-right: 8px;
  }
`;

const EditingPeriod = styled(motion(Root))`
  background: var(--primary-color);
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > *:not(:last-child) {
    margin-right: 16px;
  }
  padding: 0 16px;
`;

const CategoryColor = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  height: 14px;
  width: 14px;
  border-radius: 50%;
  place-self: center;
  margin-right: 8px;
`;

const EditText = styled.p`
  :hover {
    text-decoration: underline;
  }
  cursor: pointer;
  color: #fafafa;
`;

const GradientText = (props: { children: React.ReactNode }) => (
  <GradientShell>
    <p>{props.children}</p>
  </GradientShell>
);

const DatesText = (props: { start: string; end: string }): JSX.Element | null => {
  const { start, end } = props;
  let text: string;
  if (sameDay(start, end)) {
    text = `${formatHour(start)}h-${formatHour(end)}h`;
  } else {
    text = `${formatHour(start)}h - ${dayFormatter(end)}h`;
  }
  return <GradientText>{text}</GradientText>;
};

export interface PeriodCardProps {
  category: Category;
  description: string;
  start: string;
  end: string;
  onDelete: () => void;
  onUpdate: () => void;
}

export function PeriodCard({
  category,
  description,
  start,
  end,
  onDelete,
  onUpdate,
}: PeriodCardProps): JSX.Element | null {
  const [editing, setEditing] = useState(false);
  return (
    <Period onClick={() => setEditing(true)}>
      <CategoryColor color={category.color} />
      <GradientText>{`${category.name}${
        description ? ` - ${description}` : ""
      }`}</GradientText>
      <DatesText start={start} end={end} />
      <AnimatePresence>
        {editing && (
          <EditingPeriod
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            onClick={(e) => {
              e.stopPropagation();
              setEditing(false);
            }}
          >
            <EditText onClick={onUpdate}>Editar</EditText>
            <EditText onClick={onDelete}>Borrar</EditText>
          </EditingPeriod>
        )}
      </AnimatePresence>
    </Period>
  );
}
