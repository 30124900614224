import React, { useState, useRef } from "react";
import styled from "styled-components";
import { NewPeriod } from "../routes/home/context";
import Select, { StylesConfig } from "react-select";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es", es);

import "react-datepicker/dist/react-datepicker.css";
import { CompactDatePicker } from "./date-picker";
import { useCategory } from "../contexts/category-context";
import { useClickOutside, useKeypress } from "../util/hooks";

const Root = styled.form`
  width: 100%;
  width: 500px;
  height: 350px;
  position: relative;
  background-color: #fafafa;
  padding: 32px;
  border-radius: 8px;
  & > * {
    margin-bottom: 16px;
  }
  .react-datepicker__tab-loop {
    margin: 0;
  }
  h2 {
    color: #274c77;
    text-align: center;
    margin-bottom: 32px;
  }
  .dates-row {
    display: flex;
    & > div:first-child {
      margin-right: 16px;
    }
  }
  button {
    margin-bottom: 0;
    position: absolute;
    bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
`;

const AddCategoryIcon = styled.div`
  width: 40px;
  height: 38px;
  margin-left: 8px;
  border: 2px solid #a3cef1;
  border-radius: 4px;
  background-color: #fafafa;
  cursor: pointer;
  display: grid;
  place-content: center;
  font-size: 32px;
  font-weight: 500;
  color: #cccccc;
  :focus {
    color: #333333;
  }
  :hover {
    color: #666666;
  }
`;

const CategoryRow = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const AddCategoryRoot = styled.div`
  position: absolute;
  bottom: 104px;
  right: 35px;
  input {
    width: 215px;
    ::placeholder {
      font-size: 12px !important;
      position: relative;
      bottom: 1px;
    }
  }
`;

function AddCategory(props: {
  onSubmit: (category: { id: string; name: string; color: string }) => void;
  onClose: () => void;
}): JSX.Element | null {
  const [name, setName] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const { createCategory } = useCategory();
  useClickOutside(ref, props.onClose);
  useKeypress<HTMLDivElement>(ref, "Enter", async () => {
    if (name !== "") {
      const category = await createCategory({ name });
      props.onSubmit(category);
    }
  });
  return (
    <AddCategoryRoot ref={ref}>
      <input
        value={name}
        onChange={(event) => setName(event.target.value)}
        placeholder="Escribe una categoría y presiona enter"
      />
    </AddCategoryRoot>
  );
}

interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
}

const colourStyles: StylesConfig<ColourOption> = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    height: 42,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    background: "#fafafa",
  }),
  valueContainer: () => ({ height: 42 }),
  option: (styles, { data, isSelected }) => {
    return {
      ...styles,
      color: data.color,
      background: isSelected ? "#fafafa" : "inherit",
      cursor: "pointer",
    };
  },
  placeholder: (styles) => ({ ...styles, height: 42, padding: "8px 16px" }),
  singleValue: (styles, { data }) => ({
    ...styles,
    height: 42,
    padding: "8px 16px",
    color: data.color,
  }),
  container: (styles) => ({ ...styles, width: "100%" }),
};

export interface PeriodFormProps {
  data: Partial<NewPeriod>;
  onChange: (data: PeriodFormProps["data"]) => void;
  onSubmit: () => void;
  variant?: "create" | "update";
}

export function PeriodForm({
  data,
  onChange,
  onSubmit,
  variant = "create",
}: PeriodFormProps): JSX.Element | null {
  const { categories, canAddCategories } = useCategory();
  const [addingCategory, setAddingCategory] = useState(false);
  const { description, start, end, categoryID } = data;
  const selectOptions = [...categories.values()].map(({ id, name, color }) => ({
    value: id,
    label: name,
    color,
  }));

  return (
    <div style={{ position: "relative" }}>
      <Root
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <h2>{`${variant === "create" ? "NUEVO" : "EDITAR"} PERIODO`}</h2>
        <input
          placeholder="Introduzca una descripción (opcional)"
          value={description}
          onChange={(e) => onChange({ ...data, description: e.target.value })}
        />
        <div className="dates-row">
          <CompactDatePicker
            selected={start}
            onChange={(start) => onChange({ ...data, start })}
          />
          <CompactDatePicker
            selected={end}
            onChange={(end) => onChange({ ...data, end })}
          />
        </div>
        <CategoryRow>
          <Select
            placeholder="Selecciona una categoría"
            className="select-input"
            options={selectOptions}
            isMulti={false}
            value={selectOptions.find(({ value }) => value === categoryID)}
            onChange={(selected) =>
              onChange({ ...data, categoryID: selected?.value ?? undefined })
            }
            styles={colourStyles}
            isSearchable={false}
          />
          {canAddCategories && (
            <AddCategoryIcon onClick={() => setAddingCategory((prevState) => !prevState)}>
              +
            </AddCategoryIcon>
          )}
        </CategoryRow>
        <button type="submit">{variant === "create" ? "Añadir" : "Editar"}</button>
      </Root>
      {addingCategory && (
        <AddCategory
          onSubmit={({ id }) => {
            onChange({ ...data, categoryID: id });
            setAddingCategory(false);
          }}
          onClose={() => setAddingCategory(false)}
        />
      )}
    </div>
  );
}
