import React, { useState } from "react";
import Pie, { ProvidedProps, PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import { Group } from "@visx/group";
import { scaleOrdinal } from "@visx/scale";
import { animated, useTransition, to } from "react-spring";
import styled from "styled-components";

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  delay?: number;
  highlightColor?: string;
  onStartHover: (color: string) => void;
  onEndHover: (color: string) => void;
};

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  highlightColor,
  getKey,
  getColor,
  onStartHover,
  onEndHover,
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });
  return transitions((props, arc, { key }) => {
    const color = getColor(arc);
    const highlight = highlightColor === color;
    const disabled = !highlight && highlightColor !== undefined;
    return (
      <g
        key={key}
        onMouseEnter={() => onStartHover(color)}
        onMouseLeave={() => onEndHover(color)}
      >
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            })
          )}
          style={{
            opacity: disabled ? "0.6" : "1",
            filter: highlight
              ? "drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px)"
              : undefined,
          }}
          fill={color}
        />
      </g>
    );
  });
}

export interface CategoryFrequency {
  color: string;
  name: string;
  frequency: number;
  hours: number;
}

// accessor functions
const frequency = (d: CategoryFrequency) => d.frequency;

const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  justify-content: center;
  & > *:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 4px;
  }
`;

const LegendItem = styled.div<{ highlight: boolean; disabled: boolean }>`
  cursor: default;
  width: 220;
  display: flex;
  align-items: center;
  height: 28px;
  & > *:not(:last-child) {
    margin-right: 4px;
  }
  p {
    font-size: 15px;
  }
  .legend-label {
    font-weight: ${({ highlight }) => (highlight ? 700 : 600)};
  }
  .color-box {
    height: 16px;
    width: 16px;
    border-radius: 4px;
  }
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

export interface PieChartProps {
  width: number;
  height: number;
  data: CategoryFrequency[];
}

const margin = { top: 0, right: 0, bottom: 0, left: 0 };
const legendHeight = 220;

export function PieChart({ width, height, data }: PieChartProps): JSX.Element | null {
  const [highlightColor, setHightlightColor] = useState<string>();
  const getCategoryFrequencyColor = scaleOrdinal({
    domain: data.map(({ name }) => name),
    range: data.map(({ color }) => color),
  });
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - legendHeight - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <svg width="100%" height={height - legendHeight} style={{ overflow: "visible" }}>
        <Group top={centerY + margin.top} left={centerX + margin.left}>
          <Pie
            data={data}
            pieValue={frequency}
            pieSortValues={() => -1}
            outerRadius={({ data }) => {
              let offset = data.color === highlightColor ? 8 : 0;
              return radius + offset;
            }}
          >
            {(pie) => (
              <AnimatedPie<CategoryFrequency>
                {...pie}
                animate
                getKey={({ data: { name } }) => name}
                getColor={({ data: { name } }) => getCategoryFrequencyColor(name)}
                highlightColor={highlightColor}
                onStartHover={setHightlightColor}
                onEndHover={() => setHightlightColor(undefined)}
              />
            )}
          </Pie>
        </Group>
      </svg>
      <Legend>
        {data
          .sort((a, b) => b.frequency - a.frequency)
          .map(({ name, color, frequency, hours }) => (
            <LegendItem
              key={color}
              highlight={highlightColor === color}
              disabled={highlightColor !== color && highlightColor !== undefined}
              onMouseEnter={() => setHightlightColor(color)}
              onMouseLeave={() => setHightlightColor(undefined)}
            >
              <div className="color-box" style={{ background: color }} />
              <p className="legend-label">{name}</p>
              <p>{`- ${hours.toFixed(1)}h (${(frequency * 100).toFixed(1)}%)`}</p>
            </LegendItem>
          ))}
      </Legend>
    </div>
  );
}
