import { motion, AnimateSharedLayout } from "framer-motion";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { EmptyState } from "../../../components/empty-state";
import MultiLineChart from "../../../components/multi-line-chart";
import { PieChart } from "../../../components/pie-chart";
import { HomeData } from "../context";
import { HomeFilters } from "./filters";

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    color: var(--primary-color);
    margin-bottom: 16px;
  }
`;

const Menu = styled.div`
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 8px;
  overflow: hidden;
  height: 32px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
  background: #e7ecef;

  > div {
    position: relative;
    height: 100%;
    width: 120px;
    > * {
      width: 100%;
      height: 100%;
    }
    > div {
      box-shadow: inset 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
      position: absolute;
      top: 0;
    }
    > button {
      color: var(--primary-color);
      background: transparent;
      :hover {
        box-shadow: none;
      }
    }
  }
`;

export interface HomeChartsProps {
  chartData: HomeData["charts"];
  size: { width: number; height: number };
}

type Chart = "pie" | "line";

export function HomeCharts({ chartData, size }: HomeChartsProps): JSX.Element | null {
  const [chart, setChart] = useState<Chart>("pie");
  const chartMap: Record<Chart, JSX.Element> = useMemo(
    () => ({
      pie: (
        <PieChart
          {...size}
          height={size.height - 80}
          data={chartData.categoryFrequencies}
        />
      ),
      line: <MultiLineChart lineChart={chartData.lineChart} />,
    }),
    [chartData, size]
  );

  return (
    <Root>
      <h2>Dashboard</h2>
      <HomeFilters chart={chart} onChangeChart={setChart} />
      {chartData.categoryFrequencies.length === 0 ? (
        <EmptyState variant="pie-chart" />
      ) : (
        chartMap[chart]
      )}
    </Root>
  );
}
