import React from "react";
import styled from "styled-components";

const GradientBackground = styled.div`
  height: 100%;
  right: 0px;
  top: 0;
  width: 16px;
  position: absolute;
  background: linear-gradient(
    270deg,
    var(--white-color) 14.58%,
    rgba(250, 250, 250, 0) 100%
  );
`;

const ItemShellRoot = styled.div`
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  .expense-type-tag {
    height: 26px;
  }
`;

export interface GradientShellProps {
  children: React.ReactNode | string;
}

export const GradientShell = ({ children }: GradientShellProps): JSX.Element | null => (
  <ItemShellRoot>
    {children}
    <GradientBackground />
  </ItemShellRoot>
);
