import React from "react";
import styled from "styled-components";

const Root = styled.div`
  width: 100%;
  > p {
    margin-bottom: 2px;
    font-size: 14px;
  }
`;

export interface TextInputProps {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
}

export function TextInput({
  label,
  value,
  onChange,
  placeholder,
  type,
}: TextInputProps): JSX.Element | null {
  return (
    <Root>
      {label !== undefined && <p>{label}</p>}
      <input
        value={value}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        type={type}
      />
    </Root>
  );
}
