import React, { memo, useState } from "react";
import styled from "styled-components";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { Period, PeriodChangeset, useHome } from "../context";
import { dayToDate, formatWeekday } from "../../../util/dates";
import { PeriodCard } from "../../../components/period-card";
import { UpdatePeriod } from "../../../components/update-period";
import { EmptyState } from "../../../components/empty-state";

const Root = styled.div`
  overflow-y: auto;
  padding: 0 24px 24px 24px;
  .list-item > * {
    margin-bottom: 8px;
  }
`;

const MonthTitleRoot = styled(motion.p)`
  padding-top: 4px;
  font-weight: 700;
  font-size: 15px;
  color: var(--dark-color);
`;

function MonthTitle(props: { date: string; prevDate?: string }): JSX.Element | null {
  const { date, prevDate } = props;
  if (
    prevDate === undefined ||
    dayToDate(date).getDate() !== dayToDate(prevDate).getDate()
  ) {
    return (
      <MonthTitleRoot layoutId={formatWeekday(date)}>
        {formatWeekday(date)}
      </MonthTitleRoot>
    );
  }
  return null;
}

interface PeriodsMemoProps {
  periods: Period[];
}

function areEqual(prevProps: PeriodsMemoProps, nextProps: PeriodsMemoProps) {
  return prevProps.periods === nextProps.periods;
}

interface PeriodsListProps {
  periods: Period[];
  deletePeriod: (id: string) => Promise<void>;
  updatePeriod: (id: string, changeset: PeriodChangeset) => Promise<void>;
}

export function PeriodsList({
  periods,
  deletePeriod,
  updatePeriod,
}: PeriodsListProps): JSX.Element | null {
  const [updating, setUpdating] = useState<string>();

  const PeriodsList = memo(
    (props: PeriodsMemoProps) => (
      <>
        {props.periods.map(({ id, ...rest }, index) => {
          return (
            <div key={id} className="list-item">
              <MonthTitle date={rest.start} prevDate={periods[index - 1]?.start} />
              <motion.div layoutId={id}>
                <PeriodCard
                  {...rest}
                  onDelete={() => deletePeriod(id)}
                  onUpdate={() => setUpdating(id)}
                />
              </motion.div>
            </div>
          );
        })}
      </>
    ),
    areEqual
  );

  return (
    <Root>
      <AnimateSharedLayout>
        {periods.length > 0 ? (
          <PeriodsList periods={periods} />
        ) : (
          <EmptyState variant="periods" />
        )}
      </AnimateSharedLayout>
      <AnimatePresence>
        {updating && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <UpdatePeriod
              onUpdate={(changeset) => updatePeriod(updating!, changeset)}
              onClose={() => setUpdating(undefined)}
              period={periods.find(({ id }) => id === updating)!}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Root>
  );
}
