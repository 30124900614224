import React from "react";
import styled from "styled-components";

const EmptyStateRoot = styled.div`
  border: 1px dashed #333333;
  width: calc(100% - 32px);
  padding: 16px;
  p {
    font-weight: 600;
  }
`;

const PieEmptyStateRoot = styled(EmptyStateRoot)`
  margin-top: 48px;
`;

export interface EmptyStateProps {
  variant: "periods" | "pie-chart";
}

const EmptyStateMap: Record<EmptyStateProps["variant"], JSX.Element> = {
  periods: (
    <EmptyStateRoot>
      <p>
        Añade tu primer período pulsando el botón <strong>Nuevo</strong>. Podrás
        introducir una descripción (opcional), una fecha de inicio, fecha de finalización
        y una categoría.
      </p>
    </EmptyStateRoot>
  ),
  "pie-chart": (
    <PieEmptyStateRoot>
      <p>
        Después de añadir tu primer período verás las estadísticas de tu horario y podrás
        saber en qué inviertes tu tiempo.
      </p>
    </PieEmptyStateRoot>
  ),
};

export function EmptyState(props: EmptyStateProps): JSX.Element | null {
  return EmptyStateMap[props.variant];
}
