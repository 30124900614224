import React, { useState } from "react";
import { AnimateSharedLayout, motion } from "framer-motion";
import styled from "styled-components";
import Logo from "../../graphics/logo.png";
import { LoginCard } from "./components/login-card";
import { SignupCard } from "./components/signup-card";
import { MovementBackground } from "../../components/background";

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  position: relative;
  opacity: 0.9;

  > img {
    position: absolute;
    top: 2%;
    left: 0;
    right: 0;
    width: 100%;
    object-fit: contain;
    max-height: 125px;
  }
`;

export interface AuthenticationProps {}

export function Authentication(props: AuthenticationProps): JSX.Element | null {
  const [view, setView] = useState<"login" | "signup">("login");
  const viewComponent = {
    login: <LoginCard onChangeView={() => setView("signup")} />,
    signup: <SignupCard onChangeView={() => setView("login")} />,
  };
  return (
    <Root>
      <img src={Logo} />
      <AnimateSharedLayout>
        <motion.div
          initial={{ opacity: 0.7, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          layout="position"
        >
          {viewComponent[view]}
        </motion.div>
      </AnimateSharedLayout>
    </Root>
  );
}
