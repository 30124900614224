import React from "react";
import styled from "styled-components";

const Root = styled.div`
  .lds-hourglass {
    display: inline-block;
    position: relative;
    width: 118px;
    height: 118px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 56px solid #274c77;
    border-color: #274c77 transparent #274c77 transparent;
    animation: lds-hourglass 2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
`;

export interface HourGlassProps {}

export function HourGlass(props: HourGlassProps): JSX.Element | null {
  return (
    <Root>
      <div className="lds-hourglass" />
    </Root>
  );
}
