import React from "react";
import styled from "styled-components";

const Root = styled.p`
  :hover {
    text-decoration: underline;
  }
  margin: 8px auto 0;
  cursor: pointer;
`;

export interface CtaTextProps {
  onClick: () => void;
  screen: "login" | "signup";
}

const textMap: Record<CtaTextProps["screen"], string> = {
  login: "crear cuenta",
  signup: "login",
};

export function CtaText({ onClick, screen }: CtaTextProps): JSX.Element | null {
  return <Root onClick={onClick}>{textMap[screen]}</Root>;
}
