import { useEffect } from "react";

export function useClickOutside<T extends HTMLElement = HTMLDivElement>(
  ref: React.MutableRefObject<T | null>,
  callback: () => void,
  shouldCallBackRun = true
): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        ref?.current &&
        !ref.current.contains(event.target as Node) &&
        shouldCallBackRun
      ) {
        callback();
      }
      // alert("Click outisde");
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, shouldCallBackRun]);
}

export function useKeypress<T extends HTMLElement>(
  ref: React.MutableRefObject<T | null>,
  key: "Escape" | "Enter",
  action: () => void
): void {
  useEffect(() => {
    function onKeyup(e: any) {
      if (e.key === key) action();
    }
    ref.current?.addEventListener("keyup", onKeyup);
    return () => ref.current?.removeEventListener("keyup", onKeyup);
  }, [key, action]);
}
