import React, { createContext, useState, useContext, useEffect } from "react";
import { client } from "./client";

export interface UserCredentials {
  email: string;
  password: string;
}

export interface NewUser {
  name: string;
  email: string;
  password: string;
}

export interface User {
  id: string;
  name: string;
  email: string;
  created: string;
  updated: string;
}

interface UseUser {
  loaded: boolean;
  user?: User;
  signup: (user: NewUser) => Promise<void>;
  login: (credentials: UserCredentials) => Promise<void>;
  logout: () => Promise<void>;
}

const context = createContext<UseUser>(null as never);

export function UserContext(props: { children: React.ReactNode }): JSX.Element | null {
  useEffect(() => {
    handleGetUser();
  }, []);
  const [user, setUser] = useState<User>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const handleGetUser = async () => {
    setLoaded(false);
    try {
      const { user } = await client.$("user").get<{ user: User }>();
      setUser(user);
    } catch {
      setUser(undefined);
    }
    setLoaded(true);
  };
  return (
    <context.Provider
      value={{
        loaded,
        user,
        signup: async (newUser) => {
          await client.$("user").post({ body: { user: newUser } });
        },
        login: async (credentials) => {
          await client.$("login").post({
            body: { user: credentials },
          });
          await handleGetUser();
        },
        logout: async () => {
          setUser(undefined);
          await client.$("logout").post({});
        },
      }}
    >
      {props.children}
    </context.Provider>
  );
}

export function useUser(): UseUser {
  return useContext<UseUser>(context);
}
